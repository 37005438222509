import React from "react"
import { graphql } from "gatsby"
import { Typography, Grid, Box } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import Image from "gatsby-image"
import {
  SiteTitle,
  Section,
  RichText,
  BackButton,
  Copyright,
  MenuDrawer,
} from "../components"
import { dark, accent } from "../theme/colors"
import theme, { DarkThemeProvider } from "../theme"

const BlendBackground = styled(BackgroundImage)`
  background-blend-mode: color;
`

const ContainerGrid = styled(Grid)`
  margin: ${theme.spacing(2)}px;
  width: 100%;

  @media only screen and (max-width: 960px) {
    margin: 0px;
  }
`

const InfoGrid = styled(Grid)`
  padding: 0px ${theme.spacing(6)}px 0px ${theme.spacing(4)}px;

  @media only screen and (max-width: 960px) {
    padding: ${theme.spacing(4)}px ${theme.spacing(2)}px;
  }
`

const GroupImage = styled(Image)`
  border-radius: 5px;
  width: 100%;
  margin: 0;
  padding: ${theme.spacing(1)}px;
  background-color: ${accent};
`

export const GroeperingenPage = ({ data }) => {
  const {
    title,
    introduction,
    background,
    groeperingen,
  } = data.contentfulGroeperingenPage
  const backgrounds = [`linear-gradient(${dark}, ${dark})`, background.fluid]

  return (
    <React.Fragment>
      <MenuDrawer />
      <SiteTitle title="Groeperingen" />

      <Box
        display="flex"
        minHeight="100vh"
        flexDirection="column"
        overflow="hidden"
      >
        {/* Background image */}
        <BlendBackground fluid={backgrounds}>
          <Section
            slant="background"
            horizontalSpacing={3}
            verticalSpacing={10}
          >
            <BackButton to="../../" color="secondary" />
          </Section>
        </BlendBackground>

        {/* Rich Text Section */}
        <Section
          display="flex"
          flexGrow={1}
          horizontalSpacing={3}
          verticalSpacing={1.5}
        >
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={6} xl={6}>
              <Typography variant="h2" gutterBottom>
                {title}
              </Typography>
              <RichText>{introduction.json}</RichText>
            </Grid>
          </Grid>
        </Section>

        <Section horizontalSpacing={3} verticalSpacing={2} slant="dark">
          <Grid container justify="center">
            <Grid item xs={12} sm={10} md={8} xl={8}>
              {groeperingen.map((entry) => (
                <Box mb={2} key={entry.name} horizontalSpacing={3}>
                  <Card spacing={3}>
                    <ContainerGrid spacing={3} container>
                      <Grid xs={12} sm={12} md={4} xl={4} alignItems="">
                        <GroupImage fluid={entry.image.fluid} />
                      </Grid>
                      <InfoGrid xs={12} sm={12} md={8} xl={8}>
                        <Typography variant="h4">{entry.naam}</Typography>
                        <RichText>{entry.description.json}</RichText>
                      </InfoGrid>
                    </ContainerGrid>
                  </Card>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Section>

        {/* Footer */}
        <Section color="dark" slantCenter noPaddingTop>
          <Copyright />
        </Section>
      </Box>
    </React.Fragment>
  )
}

export const query = graphql`
  query getGroeperingenPage {
    contentfulGroeperingenPage {
      title
      introduction {
        json
      }
      background {
        fluid(maxWidth: 1920, maxHeight: 1080, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      groeperingen {
        naam
        description {
          json
        }
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default GroeperingenPage
